#launchContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#launchContainer > div {
  text-align: center;
}

#button-leweb {
	background: linear-gradient(180deg, transparent 18%, #ffd200 6.03%, #9c5708 20.93%, #f47b20 42.31%, #f79762 78.65%, #f05133 54.46%), #f05133;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 34px;
	border: none;
	color: rgb(253, 253, 253);
	font-weight: 400;
	border-radius: 28px;
	height: 45px;
	padding-left: 18px;
	padding-right: 18px;
  margin-top: 20%;
	
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease; 
}

#logoContainer {
  margin-top: 5%;
  display: flex;
  width: 100%;
}

#playbutton {
	border-radius: 34px;
	box-sizing: border-box;
	padding: 0% 2%;
	background: transparent;
}

#playbutton2 {
	border: none;
	background: none;
	box-sizing: border-box;
	padding: 0% 2%;
	background: transparent;
  color:black;
}

/* CSS */
#button-86 {
  all: unset;
  width: 150px;
  margin-top: 20%;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

#button-86::after,
#button-86::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
}

#button-86::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #28282d;
  border-radius: 10px;
}

#button-86::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

#button-86:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

#button-86:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

#button-86:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}