.containerStyle {
    color: #FF8A00;
    size: 1.25rem;
    cursor: pointer;
}
.containerStyleMobile {
    color: #FF8A00;
}

.linkStyles 
{
    display:block;
    color: #FF8A00;
    text-decoration: none;
}